

export const translations = {
    ua: {
        nav_main: "Головна",
        nav_ourus: "Про нас",
        nav_masteryDistillation: "Дисталяції",
        nav_ourAdvantages: "Переваги",
        nav_products: "Продукція",
        nav_contacts: "Контакти",
        //razdel 1
        hero__title: "Зроблено в Україні",
        hero__text: " Liberty Vodka - це 100% українська преміум-горілка. Кожна крапля - втілення вишуканої якості. Створюється із ретельно відібраних зерен кукурудзи найвищого сорту, об'єднуючи класичні технології та новаторські підходи. Найкращі фахівці України вмістили це в унікальну пляшку, яка магічно змінює своє забарвлення при охолодженні та додає естетики у ваше життя. Насолоджуйтеся смаком української бездоганності та майстерності.",
        //razdel 2
        ourus_title: "Мистецтво Виробництва",
        ourus_text1: "Наша душа втілюється в кожній пляшці, перетворюючи виробництво у справжнє мистецтво. Наш успіх базується на класичній технології і унікальному виробничому процесі, що визначає наш бренд.",
        ourus_text2: "Наші майстри дистиляції володіють справжнім мистецтвом перетворення сировини в вишукану горілку. Вони контролюють якість та безпечність на кожному етапі виробництва нопою для досягнення виняткового смаку.",
        ourus_text3: "Кожна крапля Liberty Vodka знаходить своє втілення в елегантній пляшці, яка є творінням мистецтва та підкреслює вишуканість продукту всередині.",
        //razdel 3
        masteryDistillation_title: "Мистецтво дистиляції",
        masteryDistillation_text: "Ми віримо, що дистиляція - це мистецтво, а наші майстри - віртуози. Використовуючи лише найкраще зерно та найчистіші джерела води, ми переганяємо нашу горілку невеликими партіями, що дозволяє точно контролювати кожну деталь. Результатом є оксамитово-гладкий напій з витонченою складністю, який зачарує ваше піднебіння. Liberty Vodka - це симфонія смаків, що гармонійно поєднує традиційні горілчані нотки з особливим, вишуканим характером. Наші майстри дистиляції поєднують свій досвід з перевіреними часом методами, щоб отримати надзвичайно м'який, чистий і прозорий спирт.",
        //razdel 4
        our_title: "Наші",
        advantages_title: "переваги",
        ourAdvantages_title1: "Стандарти якості",
        ourAdvantages_title2: "Ексклюзивна форма",
        ourAdvantages_title3: "Способи Використання",
        ourAdvantages_txt1: "Наша горілка виробляється відповідно до високих стандартів Міжнародної Організації Стандартизації (ISO). Це свідчить про те, що кожна крапля нашого напою відповідає не лише внутрішнім, але і міжнародно визнаним нормам безпеки та якості. За стандартами ISO, ми забезпечуємо, що кожен етап виробництва, від відбору зерена до розливу, відбувається відповідно до найвищих міжнародних вимог. Ви можете бути впевнені, що купуючи горілку Liberty, ви отримуєте продукт, який відповідає глобальним стандартам якості та вишуканості. Стандартам якості та має вишуканий та неповторний смак.",
        ourAdvantages_txt2: "Горілка Liberty ретельно продумана до найдрібніших деталей, включаючи створення унікальної форми пляшки для зручного зберігання та використання. Вона магічно змінює своє забарвлення при охолодженні та надає естетики вашому життю, створюючи власну елегантність та стиль.",
        ourAdvantages_txt3: "Унікальна рецептура Liberty дозволяє використовувати горілку, не тільки міксуючи коктейлі, а й створюючи страви, десерти з неперевершеним смаком. А особливий аромат - перемішаного зерна з подихами квітів робить будь-які напої винятковими. Горілка краще смакує охолодженою.",
        //razdel 5
        teg_txt: "Liberty Vodka - це більше, ніж напій. Це творіння, втілення вишуканості та майстерності. Кожен ковток - це подорож у світ неповторних смакових відчуттів, а кожна пляшка - твір мистецтва, що об'єднує в собі століття традицій та сучасний шарм. З Liberty Vodka кожен момент стає винятковим, а ваше святкування - елегантним. Відкрийте для себе справжнє задоволення та вишуканість в кожній краплі Liberty Vodka.",
        //razdel 6
        produckt_title: "Наша продукція",

        contact_title: "Зв'язок з нами",


        title_qr: "Поділитися з друзями",
        // forms_name: "Ім'я:",
        // forms_email: "Мейл:",
        // forms_text: "Ваше повідомлення:",
    },
    eng: {
        nav_main: "Home",
        nav_ourus: "About us",
        nav_masteryDistillation: "Distillation",
        nav_ourAdvantages: "Our advantages",
        nav_products: "Products",
        nav_contacts: "Contacts",
        //razdel 1
        hero__title: "Made in Ukraine",
        hero__text:   "Liberty Vodka is a 100% premium Ukrainian vodka, where each drop embodies exquisite quality. Crafted from carefully selected grains of the highest-quality corn, it seamlessly blends classic technologies with innovative approaches. Ukraine's top specialists have encapsulated this perfection in a unique bottle that undergoes a magical color transformation when cooled, adding an aesthetic touch to your life. Experience the taste of Ukrainian excellence and mastery.",
        //razdel 2
        ourus_title: "Art of Production",
        ourus_text1: "Our soul is embodied in every bottle, transforming production into a true art form. Our success is based on classic technology and a unique production process that defines our brand.",
        ourus_text2: "Our distillation masters possess the true art of transforming raw materials into exquisite vodka. They control temperatures, follow a precise process, and consider every nuance to achieve an exceptional flavor profile.",
        ourus_text3: "Each drop of Liberty Vodka finds its embodiment in an elegant bottle, a creation of art that emphasizes the sophistication of the product inside.",
        //razdel 3
        masteryDistillation_title: "Distillation",
        masteryDistillation_text: "We believe that distillation is an art, and our craftsmen are virtuosos. Using only the finest grains and the purest water sources, we distill our vodka in small batches, allowing us to control every detail. The result is a velvety smooth drink with subtle complexity. Liberty Vodka harmoniously combines traditional vodka notes with a special, refined character.Our master distillers combine their experience with time-honoured methods to produce an extremely soft, pure and clear spirit.",
        //razdel 4
        our_title: "Our",
        advantages_title: "advantages",
        ourAdvantages_title1: "Quality Standards",
        ourAdvantages_title2: "Exclusive Design",
        ourAdvantages_title3: "Usage Options",
        ourAdvantages_txt1: "Our vodka is produced in accordance with the high standards of the International Organization for Standardization (ISO). This ensures that every drop of our beverage not only meets internal but also internationally recognized safety and quality standards. Following ISO standards, we ensure that each stage of production, from grain selection to bottling, complies with the highest international requirements. You can be confident that by choosing Liberty Vodka, you are getting a product that adheres to global standards of quality and sophistication.",
        ourAdvantages_txt2: "Liberty Vodka is meticulously crafted down to the smallest details, including the creation of a uniquely shaped bottle for convenient storage and usage. It magically changes its color when cooled, adding aesthetics to your life. It expresses its elegance and style.",
        ourAdvantages_txt3: "The unique recipe of Liberty allows you to use vodka not only to mix cocktails, but also to create dishes and desserts with an unsurpassed taste. The special aroma of mixed grain with a whiff of flowers makes any drink exceptional. Vodka tastes better chilled.",
        //razdel 5
        teg_txt: "Liberty Vodka is more than a beverage. It is a creation, an embodiment of sophistication and craftsmanship. Each sip is a journey into a world of unique flavor sensations, and every bottle is a work of art that blends centuries-old traditions with modern charm. With Liberty Vodka, every moment becomes exceptional, and your celebrations acquire an elegant touch. Discover genuine pleasure and refinement in every drop of Liberty Vodka.",
        //razdel 6
        produckt_title: "Products",
        
        contact_title: "Contact",

        title_qr: "Share with friends",
        // forms_name: "Name:",
        // forms_email: "E-mail:",
        // forms_text: "Your messages:",

    }
};